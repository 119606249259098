import styled from 'styled-components';
import ModalBackPng from '../../../../assets/modal/background.png'
import CloseSvg from '../../../../assets/buttons/close.svg'
import ButtonPng from '../../../../assets/modal/button.png'

export const Wrapper = styled.div<{ $isVisible: boolean }>`
  display: none;
  ${props => props.$isVisible && `
    display: flex;
  `}
  transition: all 0.3s ease-out;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 200;
  width: 100%;
  height: 100%;
  background-color: rgba(3, 5, 7, 0.85);
  justify-content: center;
  align-items: center;

  @media (max-width: 800px) {
    align-items: end;
  }
`;

export const Modal = styled.div`
  border: 2px solid rgba(255, 225, 168, 1);
  height: 474px;
  width: 492px;
  border-radius: 16px;
  position: relative;
  background-image: url(${ModalBackPng});
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  text-align: center;
  &:after {
    background: linear-gradient(0deg, #000 0%, rgba(47, 38, 31, 0) 100%);
    position: absolute;
    content: '';
    display: block;
    width: 100%;
    height: 80%;
    border-radius: 16px;
  }


  @media (max-width: 800px) {
    border: 0px;
    &:after {
      border-radius: 0px;
    }
    background-position-y: -60px;
    background-size: 150%;
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
  }

`;

export const CloseButton = styled.div`
  position: absolute;
  cursor: pointer;
  top: 30px;
  right: 20px;
  width: 24px;
  height: 24px;
  background-image: url(${CloseSvg});
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  transition: all 0.1s ease-out;
  z-index: 99;
  &:hover {
    opacity: 0.8;
    }
`;


export const Title = styled.span`
  color:  rgba(255, 225, 168, 1);
  text-shadow: 1px 3px 1px rgba(47, 26, 15, 1);
  -webkit-text-stroke:  1px rgba(67, 39, 23, 1);
  text-stroke: 1px rgba(67, 39, 23, 1);
  font-family: 'Inknut Antiqua', serif;
  font-size: 40px;
  font-weight: 800;
  line-height: 48px;
  user-select: none;
  -webkit-user-select: none;
  z-index: 99;
  cursor: default;
`;

export const SubTitle = styled.span`
  margin-top: 10px;
  margin-bottom: 20px;
  font-family: 'Inknut Antiqua', serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  user-select: none;
  -webkit-user-select: none;
  max-width: 320px;
  text-shadow: 1px 2px rgba(47, 26, 15, 0.9);
  color: white;
  z-index: 99;
  cursor: default;
`;

export const ButtonText = styled.span`
  color:  rgba(255, 225, 168, 1);
  font-family: 'Inknut Antiqua', serif;
  font-size: 24px;
  font-weight: 700;
  line-height: 28px;
  user-select: none;
  -webkit-user-select: none;
  text-shadow: 0px 4px 4px rgba(25, 46, 51, 1);
`;



export const ClaimButton = styled.div`
  z-index: 99;
  background-image: url(${ButtonPng});
  background-size: cover;
  height: 80px;
  width: 296px;
  cursor: pointer;
  transition: all 0.2s ease-out;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-bottom: 35px;
  &:hover {
    &:after {
      transition: all 0.2s ease-out;
      background: radial-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0));
      position: absolute;
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      top: 0;
    }
  }
`;
