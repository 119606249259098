import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import GameMachineBg from '../src/assets/gameMachine/background.png';
import IconsPng from '../src/assets/gameMachine/icons_sprite_2x.png';

import SpinButtonPng from '../src/assets/buttons/button.png';
import SpinActiveButtonPng from '../src/assets/buttons/active_button.png';
import ScoreBgPng from '../src/assets/gameMachine/score_bg.png';

import CupPng from '../src/assets/buttons/cup.png';
import HelpButtonPng from '../src/assets/buttons/help_button.png';
import { fetchAndActivate, getRemoteConfig, getValue } from 'firebase/remote-config';
import { firebaseApp } from './config/firebase';
import { ConfigSet, ConfigType } from './providers/GameProvider.types';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

const IMAGES_TO_LOAD = [GameMachineBg, IconsPng, SpinButtonPng, SpinActiveButtonPng, ScoreBgPng, CupPng, HelpButtonPng];

Promise.all([
  new Promise<ConfigType>(async (resolve, reject) => {
    try {
      // if (process.env.NODE_ENV === 'development') {
      // resolve(DEFAULT_CONFIG);
      // } else {
      const remoteConfig = await getRemoteConfig(firebaseApp);
      remoteConfig.settings.minimumFetchIntervalMillis = 30000;
      await fetchAndActivate(remoteConfig);
      const config = await getValue(remoteConfig, 'config');
      const parsedConfig: ConfigSet = JSON.parse(config.asString());
      const params = new URLSearchParams(window.location.search);
      const setName = params.get('key');
      if (setName && setName in parsedConfig) {
        resolve(parsedConfig[setName]);
      } else {
        resolve(parsedConfig['default']);
      }
      // }
    } catch (error) {
      console.log('Failed to load config', error);
      reject(error);
    }
  }),
  ...IMAGES_TO_LOAD.map((imageSrc) => {
    return new Promise((resolve, reject) => {
      const loadImg = new Image();
      loadImg.src = imageSrc;
      loadImg.onload = () => resolve(imageSrc);
      loadImg.onerror = (err) => reject(err);
    });
  }),
])
  .then((allPromises) => {
    //@ts-ignore
    window.webkit?.messageHandlers?.handler?.postMessage('loadFinished');

    root.render(<App config={allPromises[0]} />);
  })
  .catch((err) => console.log('Failed to load', err));
