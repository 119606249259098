import styled from 'styled-components';
import HelpButtonPng from '../../assets/buttons/help_button.png';
import CupButtonSvg from '../../assets/buttons/cup.png';
import ScoreBgPng from '../../assets/buttons/score.png';
import WinBgPng from '../../assets/gameMachine/win_bg.png';

export const AppWrapper = styled.div`
@media (max-width: 800px) {
  &:after {
    background: linear-gradient(180deg, #0B0500 0%, rgba(11, 5, 0, 0.5) 49.48%, rgba(67, 35, 8, 0) 100%, rgba(11, 5, 0, 0) 100%);
    position: absolute;
    content: '';
    display: block;
    width: 100%;
    height: 20%;
    top: 0;
    left: 0;
    z-index: 5;
  }
}
`
export const GameMachineWrapper = styled.div`
  user-select: none;
  -webkit-user-select: none;
  background-color: #1b0d09;
  color: white;
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  margin: 0px;
  padding: 0px;
`;


export const HelpButtonText = styled.span`
color: rgba(255, 225, 168, 1);
  font-family: 'Inknut Antiqua', serif;
  font-size: 32px;
  font-weight: 700;
  line-height: 38px;
  user-select: none;
  -webkit-user-select: none;
  @media (max-width: 800px) {
    font-size: 22px;
  }
`;



export const HelpButton = styled.div<{ $isWebView: boolean }>`
  position: fixed;
  top: 60px;
  left: 60px;
  height: 72px;
  width: 72px;
  background-image: url(${HelpButtonPng});
  background-size: cover;
  cursor: pointer;
  z-index: 10;
  user-select: none;
  -webkit-user-select: none;
  -webkit-tap-highlight-color: transparent;

  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    &:after {
      background: radial-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0));
      position: absolute;
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      top: 0;
    }
  }

  @media (max-width: 800px) {
    height: 40px;
    width: 40px;
    top: 25px;
    left: 25px;
    ${props => props.$isWebView && `
      top: 60px;
    `}
  }
`;

export const LeaderboardButton = styled.div<{ $isWebView: boolean }>`
  position: fixed;
  top: 60px;
  right: 300px;
  height: 72px;
  width: 72px;
  background-image: url(${CupButtonSvg});
  background-size: cover;
  user-select: none;
  -webkit-user-select: none;
  -webkit-tap-highlight-color: transparent;

  cursor: pointer;
  z-index: 10;

  &:hover {
    &:after {
      background: radial-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0));
      position: absolute;
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      top: 0;
    }
  }

  @media (max-width: 1536px) {
    top: inherit;
    bottom: 70px;
    right: 70px;
  }

  @media (max-width: 800px) {
    height: 57px;
    width: 57px;
    top: 25px;
    right: 25px;
    ${props => props.$isWebView && `
      top: 60px;
    `}
  }
`;

export const Score = styled.div<{ $isWebView: boolean }>`
  cursor: default;
  position: fixed;
  top: 60px;
  right: 60px;
  height: 54px;
  width: 214px;
  background-image: url(${ScoreBgPng});
  background-size: cover;
  z-index: 10;

  @media (max-width: 800px) {
    top: 25px;
    height: 36px;
    width: 148px;
    right: calc(50% - 74px);
    ${props => props.$isWebView && `
      top: 60px;
    `}
  }
`;

export const ScoreText = styled.div`
  background-image: linear-gradient(180deg, #fde99d 0%, #c69d4b 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: 'Inknut Antiqua', serif;
  font-size: 24px;
  font-weight: 400;
  line-height: 28px;
  margin-left: 65px;
  margin-top: 12px;
  user-select: none;
  -webkit-user-select: none;
  @media (max-width: 800px) {
    font-size: 16px;
    line-height: 19px;
    margin-left: 45px;
    margin-top: 8px;
  }
`;



export const VideoWrapper = styled.div<{ $showVideo: boolean }>`
  ${props => !props.$showVideo && `
    display: none;
  `}
  position: absolute;
  z-index: 99;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);

  @media (max-height: 1100px) {
    top: inherit;
    transform: translate(-50%,50%);
    bottom: 525px;
  }
   @media (max-width: 450px) {
    top: inherit;
    transform: translate(-50%,50%);
    bottom: 410px;
   }
`
export const WinAnimation = styled.div<{ $showWinAnimation: boolean }>`
  visibility: visible;
  opacity: 1;
  ${props => !props.$showWinAnimation && `
    visibility: hidden;
    opacity: 0;
  `}

  transition:visibility 0.3s linear,opacity 0.3s linear;
   width: 939px;
   height: 939px;
   background-image: url(${WinBgPng});
   background-size: cover;

   position: absolute;
   top: 30%;
   left: 50%;
   transform: translate(-50%,-50%);

   &:before {
    background: radial-gradient(50% 50% at 50% 50%, #321A13 0%, rgba(27, 13, 9, 0) 35.83%);
    position: absolute;
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
  color:  rgba(255, 225, 168, 1);
  -webkit-text-stroke:  4px rgba(67, 39, 23, 1);
  text-stroke: 4px rgba(67, 39, 23, 1);
  font-family: 'Inknut Antiqua', serif;
  font-size: 120px;
  font-weight: 900;
  line-height: 106px;
  user-select: none;
  -webkit-user-select: none;
  cursor: default;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 800px) {
    font-size: 70px;
    line-height: 62px;
    top: 20%;
  }

  div {
    p {
      margin: 5px;
    }
    z-index: 9;
  }
`
